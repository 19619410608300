import { MenuItem } from './menu.model';

export const menuAdminCompte: MenuItem[] = [

    {
        id: 5,
        label: 'Gestion des utilisateurs',
        icon: 'users',
        link: '/configuration/gestion-utilisateurs/liste',
    }
];

export const menuOperateurImportateur: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Mes Demandes',
        icon: 'decision_anticipee',
        link: '/demande-da',
    },
    {
        id: 3,
        label: 'Mes Décisions',
        icon: 'rdv',
        link: '/decisions',
        
    },
    {
        id: 4,
        label: 'Mes Déclarants',
        icon: 'services',
        link: '/gestion-declarants',
        
    },
    {
        id: 5,
        label: 'Mes Avis',
        icon: 'rectif',
        link: '/avis',
        
    }
];

export const menuOperateurCda: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Mes Demandes',
        icon: 'decision_anticipee',
        link: '/demande-da',
    },
    {
        id: 3,
        label: 'Mes Décisions',
        icon: 'rdv',
        link: '/decisions',
        
    },
    {
        id: 4,
        label: 'Mes Importateurs',
        icon: 'services',
        link: '/gestion-importateurs',
        
    },
    {
        id: 5,
        label: 'Mes Avis',
        icon: 'rectif',
        link: '/avis',
        
    }
];

export const menuAgentDouanes: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
        
    },
    {
        id: 2,
        label: 'Demandes D.A',
        icon: 'decision_anticipee',
        link: '/demande-da',
    },
    {
        id: 3,
        label: 'Sessions',
        icon: 'rdv',
        link: '/sessions',
        
    },
    {
        id: 4,
        label: 'Décisions',
        icon: 'rectif',
        link: '/demande-rectif',
        
    }
];

export const menuCbDouanes: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Demandes D.A',
        icon: 'decision_anticipee',
        link: '/demande-da',
    },
    {
        id: 3,
        label: 'Sessions',
        icon: 'rdv',
        link: '/sessions',
        
    },
    {
        id: 4,
        label: 'Décisions',
        icon: 'rectif',
        link: '/demande-rectif',
        
    },
    {
        id: 5,
        label: 'Gestion utilisateurs',
        icon: 'users',
        isLayout: false,
        link: '/configuration/gestion-utilisateurs/liste',
    },
    {
        id: 6,
        label: 'Secteurs d\'activités',
        icon: 'users',
        isLayout: false,
        link: '/configuration/gestion-secteurs/liste',
    },
    {
        id: 7,
        label: 'Gestion des délais',
        icon: 'users',
        isLayout: false,
        link: '/configuration/gestion-delais/liste',
    }
];