import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DeclarantModel } from "../models/declarant.model";
import { OperateurCdaModel } from "../models/operateurCda.model";
import { ApiResponseListeModel } from "../models/api-response.model";
import { LocalStorageService } from "../shared/localStorage.service";
@Injectable({ providedIn: 'root' })

export class NotificationsService{
   token = this.localStorageService.token;
    accountType = this.localStorageService.acountType;
    constructor (private http: HttpClient,private localStorageService: LocalStorageService){

    }

    createNotification( notificationCreationForm){
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +this.localStorageService.token,
            
          }); 
        //console.log('Create Fiche', this.accountType, fichecCreationForm.value);
        const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/create`;
        return this.http.post<OperateurCdaModel[]>(url, notificationCreationForm,{ headers } );

    }
    getNotificationByUserEmail(userEmail): Observable<ApiResponseListeModel>{
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +this.localStorageService.token,
            
          }); 
        const _body ={
            "query": {
                "notification_userEmail":`${userEmail}`
            }
        }
        
        const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/list`;
        return this.http.post<ApiResponseListeModel>(url, _body, { headers } );
    }

    getUnreadNotificationByUserEmail(userEmail): Observable<ApiResponseListeModel>{
      const headers = new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +this.localStorageService.token,
          
        }); 
      const _body ={
          "query": {
              "notification_userEmail":`${userEmail}`,
              "notification_isRead": false,
          }
      }
      
      const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/list`;
      return this.http.post<ApiResponseListeModel>(url, _body, { headers } );
  }


    getNotificationById(notificationId): Observable<ApiResponseListeModel>{
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +this.localStorageService.token,
            
          }); 
        
        const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/${notificationId}`;
        return this.http.post<ApiResponseListeModel>(url, { headers } );
    }
    deleteNotification(id){
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +this.localStorageService.token,
            
          }); 

        const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/delete/${id}`;
        return this.http.delete<ApiResponseListeModel>(url, { headers } );
    }

    getAllNotification(): Observable<ApiResponseListeModel>{
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +this.localStorageService.token,
            
          }); 
        const _body ={
        }
        
        const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/list`;
        return this.http.post<ApiResponseListeModel>(url, _body, { headers } );
    }
    
    updateNotificationStatuts(id, statuts){
      const headers = new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +this.localStorageService.token,          
        }); 

        const _body = {
          notification_isRead: !statuts
        }
      const url= `${environment.apiDecisionAnticipeUrl}/${this.localStorageService.acountType}/api/v1/notifications/partial-update/${id}`;
      return this.http.put<OperateurCdaModel[]>(url, _body , { headers });

  }
    
}