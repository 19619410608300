import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _token: string = '';
  private _currentUser: string = '';
  private _currentOperateur: string = '';
  private _acountType: string = '';
  private _userId: number = 0;
  
  get acountType(): string {
    return this._acountType;
  }

  get token(): string {
    return this._token;
  }

  get currentUser(): string {
    return this._currentUser;
  }

  get currentOperateur(): string {
    return this._currentOperateur;
  }

  get userId(): number {
    return this._userId;
  }
  setUserId(userId: number): void {
    console.log('setUserId', userId);
    this._userId = userId;
  }

  setAccountType(acountType: string): void {
    console.log('SetAccount type: ', acountType)
    this._acountType = acountType;
  }
  setCurrentOperateur(currentOperateur: string): void {
    console.log('SetCurrent Operateur, ',currentOperateur )
    this._currentOperateur = currentOperateur;
  }

  setCurrentUser(currentUser: string): void {
    console.log('SetCurrent user, ',currentUser )

    this._currentUser = JSON.stringify(currentUser);
  }

  setToken(token: string): void {
    console.log('set token: ', token)
    this._token = token;
  }
}