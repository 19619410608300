import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/localStorage.service';
import { HostListener } from '@angular/core';
import { NotificationsService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F5') {
      event.preventDefault();
      console.log('Touche F5 appuyée');
    }
  }
  notificationsList= [];
  unreadCount: number = 0;
  name:string = ''
  email: string= '';
  company:string = '';
  code:string = '';
  userType: string = '';
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  accountType: string ='';
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
              private authFackservice: AuthfakeauthenticationService,
              public languageService: LanguageService,
              public translate: TranslateService,
              private localStorageService: LocalStorageService,
              private notificationsService: NotificationsService,
              public _cookiesService: CookieService) {
                this.accountType = this.localStorageService.acountType
  }

  listLang = [
    { text: 'Français', flag: 'assets/images/flags/french.jpg', lang: 'fr' },
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
 ngAfterViewInit(){
  console.log('top bar : ', this.accountType )

 }
  ngOnInit() {
    switch (this.localStorageService.acountType) {
    
      case 'cda':
        // Convert the JSON string back to an object
        const _operateurcda = this.localStorageService.currentOperateur;
        const operateurcda = JSON.parse(_operateurcda);
        this.code= operateurcda.operateurCDA_codeCda,
        this.userType = 'Opérateur CDA'
        this.name =operateurcda.operateurCDA_nom + ' ' + operateurcda.operateurCDA_prenoms;
        this.email = operateurcda.operateurCDA_email;
        this.getAllUserNotification()

        console.log(this.email);
        break;
        case 'admincda':
          // Convert the JSON string back to an object
          const _admincda = this.localStorageService.currentOperateur;
          const admincda = JSON.parse(_admincda);
          this.code= admincda.codeCda,
          this.userType = 'Admin CDA'

          this.name ='Admin ' + this.code;
          this.email = admincda.email;
          console.log(this.email);
          this.getAllUserNotification()

          break;
        case "importateur":
          console.log('nous sommes dans importateur');     
          const _OperateurImportateur = this.localStorageService.currentOperateur;
          // Convert the JSON string back to an object
          const OperateurImportateur = JSON.parse(_OperateurImportateur);
          this.email = OperateurImportateur.operateurImportateur_email;
          this.getAllUserNotification();
          this.userType = 'Opérateur importateur'
          this.email = operateurcda.operateurImportateur_email;
          console.log(this.email);

          if(OperateurImportateur.OperateurImportateur_code !== null ){
            this.code= OperateurImportateur.operateurImportateur_codeNCC 
          } else{
            this.code='';
          }
          this.name =OperateurImportateur.operateurImportateur_nomDirigeant  + ' ' + OperateurImportateur.operateurImportateur_prenomsDirigeant ;
          this.email = OperateurImportateur.OperateurImportateur_email;

          break;
          case 'agentdouane':
          const _douanes = this.localStorageService.currentOperateur;
          // Convert the JSON string back to an object
          const agentDouane = JSON.parse(_douanes);
          if(agentDouane.agentDoouane_type == 1){
            this.userType = 'Membre UG'
          }
          if(agentDouane.agentDoouane_type == 2){
            this.userType = 'Chef UG'
          }
          if(agentDouane.agentDoouane_type == 3){
            this.userType = 'Membre CTS'
          }
          if(agentDouane.agentDoouane_type == 4){
            this.userType = 'Président CTS'
          }
          if(agentDouane.agentDoouane_type == 5){
            this.userType = 'DG Douanes'
          }
          if(agentDouane.agentDouane_matricule !== null ){
            this.code=   agentDouane.agentDouane_matricule
          } else{
            this.code='';
          }
          this.name =agentDouane.agentDouane_nom + ' ' + agentDouane.agentDouane_prenoms;
          this.email = agentDouane.agentDouane_email;
          this.getAllUserNotification()
          break;
      default:
        this.logout();
        console.log('top bar default: ', this.accountType )
  
        break;
    }
    this.accountType = this.localStorageService.acountType
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/french.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }
  getAllUserNotification() {
    this.notificationsList = [];
    console.log('Get getAllUserNotification userEmail',this.email);

    //this.exportateurService.getImportateurByCode(this.registerForm.get('exportateurCode').value).subscribe 
    this.notificationsService.getUnreadNotificationByUserEmail(this.email).subscribe(
      (data) => {
        if (data) {
          this.notificationsList = data.data.data;
          this.notificationsList.forEach(element => {
            if(element.notification_isRead ==false){
              this.unreadCount = this.unreadCount + 1;
            }
          });
          console.log('getAllUserNotification list', this.notificationsList)
        } else {
          // this.selectedExportateur= undefined;
          this.notificationsList = [];

        }
    },  
  (error) => {
  
        console.error('Une erreur est survenue lors de la récupération des données :', error);
  
      }
    )
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  changeNotificationStatus(notification){
    this.unreadCount = 0;
    console.log('changeNotificationStatus');
    this.notificationsService.updateNotificationStatuts(notification.id, notification.notification_isRead).subscribe(
      (data) => {
        this.getAllUserNotification();
        if (data) {
          console.log('changeNotificationStatus: data ' + data);
        } else {
          console.log('changeNotificationStatus error');
        }
      },
         
  (error) => {
    this.getAllUserNotification()
        //this.selectedExportateur= undefined;
        console.error('Une erreur est survenue lors de la récupération des données :', error);
  
      }
    );
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    sessionStorage.clear()
    if (environment.defaultauth === 'firebase') {
      //this.authService.logout();
    } else {
      //this.authFackservice.logout();
    }
   this.router.navigate(['/authentification/connexion']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
